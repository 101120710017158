import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {ArrowLeft} from '@styled-icons/feather/ArrowLeft';
import {ArrowRight} from '@styled-icons/feather/ArrowRight';
import * as Ant from 'antd';

export default function MagazineDetail(props) {
  const params = qs.parse(props.location.search);
  const [record, setRecord] = React.useState(null);

  React.useState(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setRecord(
          await JStorage.fetchOneDocument('Magazine', {
            id: params.id,
          }),
        );
      } catch (ex) {
        console.warn('MagazineDetail ex', ex);
      }
      AppActions.setLoading(false);
    }

    if (params.id) {
      fetchData();
    }
  }, [params.id]);

  return (
    <Wrapper>
      <div className="content">
        <Ant.Button
          style={{display: 'flex', alignItems: 'center', paddingLeft: 0}}
          href={'/law-service/?group=online&category=magazine'}
          type="link"
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate('/law-service/?group=online&category=magazine');
          }}>
          <ArrowLeft size={20} />
          <div style={{fontSize: 18, marginLeft: 6}}>返回所有會刊</div>
        </Ant.Button>

        {record && (
          <div className="main">
            <header>
              <h3>{`第${record.period || '---'}期`}</h3>
              <h2>{record.title}</h2>
            </header>

            <div className="start-time">
              上架日期：{(record.Start_Time || '').slice(0, 10)}
            </div>

            <div className="row" style={{justifyContent: 'center'}}>
              <Ant.Button
                style={{margin: 6}}
                type={'link'}
                target="_blank"
                href={record.pdf_sample}>
                試閱版下載
              </Ant.Button>
              <Ant.Button
                style={{margin: 6}}
                type={'primary'}
                target="_blank"
                href={record.pdf}>
                完整版下載
              </Ant.Button>
            </div>

            <img src={record.cover} alt="cover" className="cover" />

            <div className="details">
              <h2 style={{textAlign: 'center'}}>精彩內容</h2>
              {!Array.isArray(record.details) && (
                <div style={{textAlign: 'center'}}>目前查無章節紀錄</div>
              )}
              {((Array.isArray(record.details) && record.details) || []).map(
                (part, partIdx) => {
                  return (
                    <div
                      key={partIdx}
                      className="row"
                      style={{
                        padding: 20,
                        borderBottom: '1px solid #ccc',
                      }}>
                      <div style={{flex: 1}}>
                        <div style={{fontSize: 14, color: '#888'}}>
                          {part.sub_title || '專欄報導'}
                        </div>
                        <div style={{fontSize: 18}}>{part.title}</div>
                      </div>
                      {/*
                    <Ant.Button type="link">
                      <ArrowRight size={18} />
                    </Ant.Button>
                    */}
                    </div>
                  );
                },
              )}
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: var(--topNavBarHeight) 20px 80px;
  min-height: calc(100vh - 200px);

  .row {
    display: flex;
    align-items: center;
  }

  & > .content {
    margin: 40px auto;
    max-width: 800px;

    & > .main {
      & > header {
        padding: 20px 0;
        margin-bottom: 10px;
        border-bottom: 5px solid #b49664;
        h2,
        h3 {
          margin: 0;
          padding: 0;
        }
        h3 {
          color: #888;
        }
      }

      .start-time {
        text-align: right;
        color: #888;
        margin-bottom: 10px;
      }

      img.cover {
        display: block;
        margin: 20px auto;
        max-width: 600px;
        object-fit: contain;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.33);
        @media only screen and (max-width: 720px) {
          width: calc(100% - 80px);
        }
      }
    }
  }
`;
